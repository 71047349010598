.windows_constructor {

  flex-grow: 1;
  background: #fffffff0;
  padding: 16px 0;

  .schema {

    width: calc(100% + 20px);
    min-height: 140px;
    margin-left: -10px;
    margin-top: 6px;
    margin-bottom: 10px;
    padding: 24px 10px 6px 60px;
    overflow-x: auto;
    background-color: #F9FFF9;
    perspective: 300px;
    
    &_draggable {
  
      height: 180px;
  
      ul {
  
        display: flex;
        margin-left: 0;
        padding-left: 0;
        margin: unset !important;
        position: relative;
        top: -22px;
  
        li { list-style-type: none !important; }
  
      }
  
      .window {
  
        padding-top: 12px;
        height: 120px;
  
      }
  
    }

    $partsize_edge_width: 3px;
    $partsize_edge_bgcolor: #9094ee;

    &__partsize {

      padding-left: 60.5px;
      padding-right: 10.5px;
      padding-bottom: 6px;
      height: 42px;
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
      display: flex;

      &__el {

        height: 100%;
        border-top: $partsize_edge_width solid $partsize_edge_bgcolor;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        & + .schema__partsize__el { margin-left: 64px; }

        &::before,
        &::after {

          $partsize_vertical_edge_height: 12px;

          content: "";
          position: absolute;
          top: -$partsize_vertical_edge_height;
          width: $partsize_edge_width;
          height: $partsize_vertical_edge_height;
          background: $partsize_edge_bgcolor;
          
        }

        &::before { left: 0; }        
        &::after  { right: 0; }

        &__size { 
          
          width: 100%;
          max-width: 40px;
          margin: 4px 4px;
          padding: unset;
          font-size: 16px;
          font-weight: 500;
          font-family: Roboto;
          text-align: center;
          color: #5155a5;
          background: unset;
          border: unset;

          &:hover,
          &:focus { outline: none; }

          &:not(input) { user-select: none; }
        
        }

      }

    }

    &__heightsize {

      $heightsize_edge_width: 8px;

      height: 91px;
      width: 52px;
      position: absolute;
      top: 20px;
      left: -60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: $partsize_edge_width solid $partsize_edge_bgcolor;

      &::before,
      &::after {

        content: "";
        position: absolute;
        right: -$heightsize_edge_width;
        height: $partsize_edge_width;
        width: $heightsize_edge_width;
        background: $partsize_edge_bgcolor;
        
      }

      &::before { top: 0; }        
      &::after  { bottom: 0; }

      &__size { 
          
        width: 100%;
        max-width: 40px;
        margin: 12px 4px 6px;
        padding: unset;
        font-size: 16px;
        font-weight: 500;
        font-family: Roboto;
        text-align: center;
        color: #5155a5;
        background: unset;
        border: unset;

        &:hover,
        &:focus { outline: none; }

        &:not(input) { user-select: none; }
      
      }

    }
  
  }
  
  .window_params {
  
    display: flex;
    width: 100%;
    margin: auto;
  
    &__main { 
  
      max-width: 220px; 
      margin: unset; 
      margin-left: 10px; 
  
    }
  
    svg {
  
      height: 18px;
      &:first-child{ min-width: 20px; }
      &:last-child { min-width: 20px; height: 19px; }
  
    }
  
    &__inputs {
  
      display: flex;
      margin: 0 12px;
  
      .textfield {
  
        &__title { text-align: center; }
        input { text-align: center; }
        & + .textfield { margin-left: 12px; }
  
      }
  
    }
  
    .remove_window {

      &_container {

        margin: 6px auto 18px;
        font-size: 15px;
        color: #bf6262;
        
      }
  
      min-width: calc( 42px - ( ( 42px / 100 ) * var( --dpi-zoom, 1 ) ) ) !important;
      min-height: calc( 40px - ( ( 40px / 100 ) * var( --dpi-zoom, 1 ) ) );
      margin-right: calc( 2px - ( ( 2px / 100 ) * var( --dpi-zoom, 1 ) ) );

      position: relative;
      top: -1px;
      margin-right: 10px;
  
    }
  
  }

  .tip {

    font-size: calc( 14px - ( ( 14px / 100 ) * var( --dpi-zoom, 1 ) ) );
    text-align: center;
    color: #7ABB5C;
    margin-bottom: calc( 20px - ( ( 20px / 100 ) * var( --dpi-zoom, 1 ) ) );

  }

  .inputs {
  
    .textfield {
  
      margin-bottom: calc( 5px - ( ( 5px / 100 ) * var( --dpi-zoom, 1 ) ) );
  
      &__title { 
      
        min-height: calc( 26px - ( ( 26px / 100 ) * var( --dpi-zoom, 1 ) ) );
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      
      }

      input { text-align: center; }
  
    }
  
  }

}

.windows_constructor--readOnly {

  .schema {
    pointer-events: none;
    user-select: none;
  }

  .window-move-anchor,
  .selected_arrow { display: none; }

  .window--selected {
    
    .window__size { color: #7ABB5C !important; }

    svg {

      path { fill: rgb(122, 187, 92) !important; }
      rect { stroke: rgb(122, 187, 92) !important; }

    }
  
  }

}