.window {

  position: relative;
  width: 82px;
  height: 91px;
  transition: 0.3s;

  &-4 { width: 64px; }

  &--selected { 
    
    svg {
      
      rect { stroke: #FFC542; }
      path { fill: #FFC542; }
    
    } 

    .window__size { color: #FFC542; }

    .selected_arrow {

      position: absolute;
      top: -14px;
      transform: rotate(180deg);

    }
  
  }

  &--mirrored { 
    
    transform: rotateY( 180deg );
    .window__size { transform: rotateY(180deg); }
  
  }

  &-move-anchor {

    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 4px;
    z-index: 2;

  }

  &__size {

    position: absolute;
    bottom: -15px;
    font-weight: 500;
    color: #7ABB5C;
    transition: 0.3s;

  }

}

.window_flying {

  height: 160px;
  padding-top: 21px;
  list-style-type: none;

  .window__size {
    
    bottom: -23px;
  }

}